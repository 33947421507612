import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/kyc.css';
import { Pagination } from '@mui/material';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';


const ImageSliderPopup = ({ setShowImageSliderPopup, userId, documentCurrentState }) => {
  const jwtToken="Bearer "+ localStorage.getItem('token')
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setImage(null);
        setError(null);
        const apiUrl = `https://www.cyberpaneltest.website:8282/kyc/getallimageDoc?columnName=${documentCurrentState}&userid=${userId}`;
        console.log(apiUrl);
        const response = await axios.get(apiUrl, {headers: { Authorization: jwtToken },responseType: 'blob'});
        const blob = new Blob([response.data], { type: 'image/jpg' });
        const url = URL.createObjectURL(blob);

        setImage({
          url,
          description: `${documentCurrentState} Image of ${userId}`
        });
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
    return () => {
      if (image && image.url) {
        URL.revokeObjectURL(image.url);
      }
    };
  }, [documentCurrentState, userId]);

  return (
    <div className="image-slider-popup">
      <span className="close" onClick={() => setShowImageSliderPopup(false)}>&times;</span>
      <div className="image-slider-content">
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {image && (
          <img src={image.url} alt={image.description} />
        )}
      </div>
    </div>
  );
};


const Kyc = () => {
  const jwtToken="Bearer "+ localStorage.getItem('token')
  const [data, setData] = useState([]);
  const [apiUrl, setApiUrl] = useState();
  const [startDate, setStartDate] = useState(getToday());
  const [endDate, setEndDate] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [totalpage, setTotalPage] = useState(1);
  const [approvalStatus, setApprovalStatus] = useState('pending');
  const [rejectStatus, setRejectStatus] = useState('pending');
  const [recordLabels, setRecordLabels] = useState([]);

  useEffect(() => {
    setData([]);
    if(endDate) {
    const apiUrl = `https://www.cyberpaneltest.website:8282/kyc/getkycreqbydaterange?startDate=${endDate}&endDate=${startDate}&page=${pageNo}`
    setApiUrl(apiUrl);
    }
    else {
    const apiUrl = `https://www.cyberpaneltest.website:8282/kyc/getkycreqbydate?date=${startDate}&page=${pageNo}`;
    setApiUrl(apiUrl);
    }

    axios.get(apiUrl, {headers: {Authorization: jwtToken}} )
      .then((res) => {
        setData(res.data.content);
        setTotalPage(res.data.totalPages);
        const initialLabels = Array(res.data.content.length).fill('Reject');
        setRecordLabels(initialLabels);   
      })
      .catch((err) => console.error(err));
    }, [startDate, endDate, pageNo, apiUrl,approvalStatus,rejectStatus]);

    const handleSortChange = (event) => {
    const selectedOption = event.target.value;
    switch (selectedOption) {
      case 'today':
        setStartDate(getToday());
        setEndDate(null);
        setPageNo(0);
        break;
      case 'yesterday':
        setStartDate(getYesterday());
        setEndDate(null);
        setPageNo(0);
        break;
      case 'last7Days':
        setStartDate(getToday());
        setEndDate(getLast7Days());
        setPageNo(0);
        break;
      case 'last30Days':
        setStartDate(getToday());
        setEndDate(getLast30Days());
        setPageNo(0);
        break;
      case 'thisMonth':
        setStartDate(getToday());
        setEndDate(getThisMonth());
        setPageNo(0);
        break;
      case 'lastMonth':
        setStartDate(getThisMonth());
        setEndDate(getLastMonth());
        setPageNo(0);
        break;
      default:
        break;
    }
    };

    function getFormattedDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
    function getToday() {
      const today = new Date();
      return getFormattedDate(today);
    }
    function getYesterday() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return getFormattedDate(yesterday);
    }
    function getLast7Days() {
      const last7Days = new Date();
      last7Days.setDate(last7Days.getDate() - 6);
      return getFormattedDate(last7Days);
    }
    function getLast30Days() {
      const last30Days = new Date();
      last30Days.setDate(last30Days.getDate() - 29);
      return getFormattedDate(last30Days);
    }
    function getThisMonth() {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      return getFormattedDate(firstDayOfMonth);
    }       
    function getLastMonth() {
      const today = new Date();
      const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      return getFormattedDate(firstDayOfLastMonth);
    }


    const [selectedUserId, setSelectedUserId] = useState(null);
    const [showApproveDialog, setShowApproveDialog] = useState(false);
    const openApproveDialog = () => setShowApproveDialog(true);
    const closeApproveDialog = () => setShowApproveDialog(false);

    const [showRejectDialog, setShowRejectDialog] = useState(false);
    const openRejectDialog = () => setShowRejectDialog(true);
    const closeRejectDialog = () => setShowRejectDialog(false);

    const handleApproveKycStatus = (userId) => {
      axios.post(`https://www.cyberpaneltest.website:8282/kyc/updatekycstatus?userid=${userId}&condition=true`,{},{headers: {Authorization: jwtToken}})
      .then(response => {
        setApprovalStatus('COMPLETED');
      })
      .catch(error => {
        console.error('Error during approval', error);
        setApprovalStatus('ERROR');
      })
      .finally(() => {
      });
    };

    const handleRejectKycStatus = (userId) => {
      axios.post(`https://www.cyberpaneltest.website:8282/kyc/updatekycstatus?userid=${userId}&condition=false`, {} ,{headers: {Authorization: jwtToken}})
      .then(response => {
        setRejectStatus('DECLINED');
      })
      .catch(error => {
        console.error('Error during reject', error);
        setRejectStatus('ERROR');
      })
      .finally(() => {
      });
    };

    const handleApproveReject = async (userId, index) => {  
    setSelectedUserId(null);
    if (recordLabels[index] === 'Approve') {
        setApprovalStatus('pending');
        setShowApproveDialog(true);
        setSelectedUserId(userId);
        const updatedLabels = [...recordLabels];
        updatedLabels[index] = 'Reject';
      setRecordLabels(updatedLabels);
    }
    if (recordLabels[index] === 'Reject') {
        setRejectStatus('pending');
        setShowRejectDialog(true);
        setSelectedUserId(userId);      
        const updatedLabels = [...recordLabels];
        updatedLabels[index] = 'Reject';
        setRecordLabels(updatedLabels);
    }    
    };

    const [showImageSliderPopup, setShowImageSliderPopup] = useState(false);
    const handleViewImageSlider = (userId) => {
    setShowImageSliderPopup(true);
    setDocumentImageIndex(0);
    setSelectedUserId(null);
    setSelectedUserId(userId);
    };

    const [documentImageIndex, setDocumentImageIndex] = useState(0);
    const documentImageStates = ['UinDoc', 'PanDoc', 'Passbook'];
    const handleNextDocument = () => {
    setDocumentImageIndex((prevIndex) => (prevIndex + 1) % documentImageStates.length);
    };
    const handlePrevDocument = () => {
    setDocumentImageIndex((prevIndex) =>
      prevIndex === 0 ? documentImageStates.length - 1 : prevIndex - 1
    );
    };

  return (
  <>
    <div className='main-container'>
      <div className="modal-conten">
          <div className="modal-content-header">
              <h3 id="kyc">KYC Approval</h3>
              <div id='sort-by'>
                <label htmlFor="stateSelect">SORT BY : </label>
                <select id="stateSelect" onChange={handleSortChange}>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="last7Days">Last 7 Days</option>
                  <option value="thisMonth">This Month</option>
                  <option value="last30Days">Last 30 Days</option>
                  <option value="lastMonth">Last Month</option>
                </select>
              </div>
          </div>
          <div id="modalContent">
            <table  className="dynamicTable">
              <thead>
                <tr>
                  <td> User Id</td>
                  <td> Name</td>
                  <td> Bank Name</td>
                  <td> Account No</td>
                  <td> Bank Address</td>
                  <td> IFSC</td>
                  <td> Status</td>
                  <td> Documents</td>
                  <td> Action</td>
                </tr>
              </thead>

              <tbody>
                {
                  data.length > 0 ?
                  (
                  data.map((getKyc, index) => (
                  <tr key={index}>
                    <td>{getKyc.userId}</td>
                    <td>{getKyc.name}</td>
                    <td>{getKyc.bankName}</td>
                    <td>{getKyc.accountNo}</td>
                    <td>{getKyc.bankAddress}</td>
                    <td>{getKyc.ifsc}</td>
                    <td>{getKyc.kycStatus}</td>
                    <td id="action-1">
                      <span id="symbol-1" onClick={handlePrevDocument} >&lt;</span>
                      <button className='view-button' onClick={() => handleViewImageSlider(getKyc.userId)}>View</button>
                      <span id="symbol-1" onClick={handleNextDocument}>&gt;</span>
                    </td>
                    <td id="action2">
                    <span
                      id="symbol-2"
                      onClick={() => setRecordLabels([...recordLabels.slice(0, index), 'Reject', ...recordLabels.slice(index + 1)])}
                      style={{ color: recordLabels[index] === 'Approve' ? 'green' : 'red' }}
                    >&lt;
                    </span>

                    <button
                      className={`reject-button ${recordLabels[index] === 'Approve' ? 'approve-btn' : 'reject-btn'}`}
                      onClick={() => {handleApproveReject(getKyc.userId, index);}}
                    >{recordLabels[index]}
                    </button>

                    <span
                      id="symbol-2"
                      onClick={() => setRecordLabels([...recordLabels.slice(0, index), 'Approve', ...recordLabels.slice(index + 1)])}
                      style={{ color: recordLabels[index] === 'Approve' ? 'green' : 'red' }}
                    >&gt;
                    </span>
                    </td>
                  </tr>
                ))
                ):(<tr style={{}}><td colSpan={8} style={{}} >NO Content</td></tr>)
                }
              </tbody>
            </table>
          </div>
      </div>

      {showImageSliderPopup && (
      <ImageSliderPopup setShowImageSliderPopup={setShowImageSliderPopup}  userId={selectedUserId}   documentCurrentState={documentImageStates[documentImageIndex]} />
      )}

      <div className='paginationsButton-child'>
            <Pagination
                count={totalpage}
                color="primary"
                defaultPage={1}
                page={pageNo+1}
                onChange={(e, value) => (setPageNo(value-1))}
                style={{ boxShadow: '0px 3px 10px gray',borderRadius:'6px',padding:'5px 0px'}} 
                sx={{
                    '& .MuiPaginationItem-root': {
                        border: 'none',
                    },
                    }}
            />
      </div> 
    </div>

    <Dialog open={showApproveDialog} onClose={closeApproveDialog}>
        <DialogTitle fontSize={'25px'} fontWeight={700} textAlign={'right'}><span className="close" onClick={() => {setShowApproveDialog(false);}}>&times;</span></DialogTitle>
        <DialogContent>
            {approvalStatus === 'pending' && ( <h3><a href='#'>KYC</a> is pending need approval..? </h3> )}
            {approvalStatus === 'COMPLETED' && ( <h3 style={{ color: 'green' }} >KYC Completed for {selectedUserId}</h3> )}
            {approvalStatus === 'ERROR' && ( <h3 style={{ color: 'red' }}>Internal Server Error</h3> )}
        </DialogContent>

        <DialogActions  style={{ display: 'flex', justifyContent:'center',alignItems:'center',marginBottom:'5px'}}>
            {approvalStatus === 'pending' && (
            <>
                <Button style={{ color: 'white',borderRadius:'16px'}}  onClick={() => handleApproveKycStatus(selectedUserId)} variant='contained' color='primary'>Approve</Button>
            </>
            )}

            {approvalStatus === 'COMPLETED' && (
                <Button style={{ color: 'white',borderRadius:'16px'}}  onClick={() => {setShowApproveDialog(false)}} variant='contained' color='primary'>OK</Button>
            )}
            {approvalStatus === 'ERROR' && (
                <Button style={{ color: 'white',borderRadius:'16px'}}  onClick={() => {setShowApproveDialog(false)}} variant='contained' color='primary'>OK</Button>
            )}
        </DialogActions>
    </Dialog>

    <Dialog open={showRejectDialog} onClose={closeRejectDialog}>
        <DialogTitle fontSize={'25px'} fontWeight={700} textAlign={'right'}><span className="close" onClick={() => {setShowRejectDialog(false);}}>&times;</span></DialogTitle>
        <DialogContent>
            <DialogContentText></DialogContentText>
            {rejectStatus === 'pending' && ( <h3>Are you Sure you want to <a href='#'>Decline?..</a></h3> )}
            {rejectStatus === 'DECLINED' && ( <h3 style={{ color: 'green' }} >KYC is Rejected for {selectedUserId}</h3> )}
            {rejectStatus === 'ERROR' && ( <h3 style={{ color: 'red' }} >Internal Server Error</h3> )}
        </DialogContent>
        
        <DialogActions style={{ display: 'flex', justifyContent:'center',alignItems:'center',marginBottom:'5px'}}>
            {rejectStatus === 'pending' && (
            <>
                <Button style={{ color: 'white',borderRadius:'20px'}}  onClick={() => handleRejectKycStatus(selectedUserId)} variant='contained' color='primary'>Yes</Button>
                <Button style={{ color: 'white',borderRadius:'20px'}}   onClick={() => {setShowRejectDialog(false)}} variant='contained' color='primary'>No</Button>
            </>
            )}
            {rejectStatus === 'DECLINED' && (
                <Button style={{ color: 'white',borderRadius:'16px'}}   onClick={() => {setShowRejectDialog(false);}} variant='contained' color='primary'>OK</Button>
            )}
            {rejectStatus === 'ERROR' && (
                <Button style={{ color: 'white',borderRadius:'16px'}}   onClick={() => {setShowRejectDialog(false);}} variant='contained' color='primary'>OK</Button>
            )}
        </DialogActions>
    </Dialog>
  </>
  );
};

export default Kyc;