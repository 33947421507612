import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Payout.css'
import { Pagination } from '@mui/material';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';

const rowsPerPage = 10;

const Payout = () => {
  const jwtToken="Bearer "+ localStorage.getItem('token')
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpage, setTotalPage] = useState(1);
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState('COMPLETE');
  const [payStatus, setPayStatus] = useState('pending');
  const [declineStatus, setDeclineStatus] = useState('pending');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState({
    userId: "",
    amount: "",
    transType: "",
    comment: ""
});

const handleChange = (e) => {
  const { name, value } = e.target;
  setSelectedUserData({
      ...selectedUserData,
      [name]: value
  });
};

  useEffect(() => {
      fetchData(activeTab);
      populateTable();
  }, [activeTab,declineStatus,payStatus]);

  const fetchData = async (status) => {
      try {
          setData([]);
          const response = await axios.get(`https://www.cyberpaneltest.website:8282/wallet/getDebitTrans/${status}`,{headers: {Authorization: jwtToken}});
          setData(response.data);
          setCurrentPage(1);
        //setTotalPage(res.data.totalPages);
          const totalPages = Math.ceil(response.data.length / rowsPerPage);
          setTotalPage(totalPages);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  const populateTable = () => {
      const startIndex = (currentPage - 1) * rowsPerPage;
      const endIndex = startIndex + rowsPerPage;
      const displayData = data.slice(startIndex, endIndex);

      return data.length > 0 ?(
       displayData.map((item, index) => (
          <tr key={index}>
              <td>{item.userId}</td>
              <td>{item.name}</td>
              <td className='status-button'><button className={item.kycStatus === 'COMPLETE' ? 'completeKyc_btn':'pendingKyc_btn' }>{item.kycStatus}</button></td>
              <td>{item.amount}</td>
              <td className='pay_decline_td'>
                  {activeTab === 'COMPLETE' ? (
                      <p className='complete'>{item.status}</p>) : (
                      <>
                          <button className="pay" onClick={() => {
                            setSelectedUserId(item.userId);     
                            const updatedUserData = {userId: item.userId, amount: item.amount+'.00', transType: item.transType, comment:'Accepted by Admin'};
                            setSelectedUserData(updatedUserData);
                            setShowPayDialog(true);}}>Pay</button>

                          <button className="decline" onClick={() => {
                            setSelectedUserId(item.userId); 
                            const updatedUserData = {userId: item.userId, amount: item.amount+'.00', transType: item.transType, comment:'Rejected by Admin'};
                            setSelectedUserData(updatedUserData);
                            setShowDeclineDialog(true);}}>Decline</button>
                      </>
                  )}
              </td>
          </tr>
      ))
      ):(<tr><td colSpan={5} style={{}} >NO Content</td></tr>)
  };

  const [showPayDialog, setShowPayDialog] = useState(false);
  const openPayDialog = () => setShowPayDialog(true);
  const closePayDialog = () => setShowPayDialog(false);

  const [showDeclineDialog, setShowDeclineDialog] = useState(false);
  const openDeclineDialog = () => setShowDeclineDialog(true);
  const closeDeclineDialog = () => setShowDeclineDialog(false);

  const handlePay = (userId) => {
      axios.post(`https://www.cyberpaneltest.website:8282/wallet/updateTrans`, selectedUserData, {headers: {Authorization: jwtToken}})
      .then(response => {
        setPayStatus('COMPLETED');
        console.log('pay successfully');
      })
      .catch(error => {
        console.error('Error during pay', error);
        setPayStatus('ERROR');
      })
      .finally(() => {
      });
  };

  const handleDecline = (userId) => {
      axios.post(`https://www.cyberpaneltest.website:8282/wallet/updateTrans`, selectedUserData ,{headers: {Authorization: jwtToken}})
      .then(response => {
        setDeclineStatus('DECLINED');
      })
      .catch(error => {
        console.error('Error during decline', error);
        setDeclineStatus('ERROR');
      })
      .finally(() => {
      });
  };

  return (
    <>
    <div className="content">
        <div className="tab_btns">
          <button className={`tablink ${activeTab === 'PENDING' ? 'active' : ''}`} onClick={() => setActiveTab('PENDING')}>PENDING</button>
          <button className={`tablink ${activeTab === 'COMPLETE' ? 'active' : ''}`} onClick={() => setActiveTab('COMPLETE')}>COMPLETE</button>
        </div>

        <div className='payout'>
            <div className='payout_head' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3>Pay Out</h3>
            </div>

            <div className="payout_scroll">
              <table className='payout_table'>
                  <thead>
                    <tr>
                      <th>User Id</th>
                      <th>Name</th>
                      <th>KYC Status</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{populateTable()}</tbody>
              </table>
            </div>
        </div>
      </div>

      <div className="pagination">
      <Pagination
          count={totalpage}
          color="primary"
          defaultPage={1}
          page={currentPage}
          onChange={(e, value) => setCurrentPage(value)}
          style={{ boxShadow: '0px 3px 10px gray',borderRadius:'6px',padding:'5px 0px'}} 
          sx={{
              '& .MuiPaginationItem-root': {
              border: 'none',
            },
          }}
      />     
    </div>
    <Dialog open={showPayDialog} onClose={closePayDialog}>
        <DialogTitle fontSize={'25px'} fontWeight={700} textAlign={'right'} marginTop={'-12px'}><span className="close" onClick={() => {setShowPayDialog(false);}}>&times;</span></DialogTitle>
        <DialogContent>
            {payStatus === 'pending' && ( 
              <>
              <h3>Are you sure you want to pay </h3>
              {/* <input type="text" name="comment" value={selectedUserData.comment} onChange={handleChange} placeholder='Comment' required/> */}
              </>
              )}
            {payStatus === 'COMPLETED' && ( <h3 style={{ color: 'green' }} >Pay Successfull for {selectedUserId}</h3> )}
            {payStatus === 'ERROR' && ( <h3 style={{ color: 'red' }} >Internal Server Error</h3> )}
        </DialogContent>

        <DialogActions  style={{ display: 'flex', justifyContent:'center',alignItems:'center',marginBottom:'5px'}}>
            {payStatus === 'pending' && (
            <>
                <Button style={{ color: 'white',borderRadius:'16px',padding:'3px'}}  onClick={() => handlePay(selectedUserId)} variant='contained' color='primary'>Yes</Button>
                <Button style={{ color: 'white',borderRadius:'20px',padding:'3px'}}  onClick={() => {setShowPayDialog(false)}} variant='contained' color='primary'>No</Button>
            </>
            )}
            {payStatus === 'COMPLETED' && (
                <Button style={{ color: 'white',borderRadius:'16px'}}  onClick={() => {setShowPayDialog(false)}} variant='contained' color='primary'>OK</Button>
            )}
            {payStatus === 'ERROR' && (
                <Button style={{ color: 'white',borderRadius:'16px'}}  onClick={() => {setShowPayDialog(false)}} variant='contained' color='primary'>OK</Button>
            )}
        </DialogActions>
    </Dialog>

    <Dialog open={showDeclineDialog} onClose={closeDeclineDialog}>
        <DialogTitle fontSize={'25px'} fontWeight={700} textAlign={'right'} marginTop={'-12px'}><span className="close" onClick={() => {setShowDeclineDialog(false);}}>&times;</span></DialogTitle>
        <DialogContent>
            {declineStatus === 'pending' && ( 
              <>
              <h3>Are you Sure you want to <a href='#'>Decline?..</a></h3> 
              {/* <input type="text" name="comment" value={selectedUserData.comment} onChange={handleChange} placeholder='Comment' required/> */}
              </>
              )}
            {declineStatus === 'DECLINED' && ( <h3 style={{ color: 'green' }} >Declined Successfull for {selectedUserId}</h3> )}
            {declineStatus === 'ERROR' && ( <h3 style={{ color: 'red' }} >Internal Server Error</h3> )}
        </DialogContent>
        
        <DialogActions style={{ display: 'flex', justifyContent:'center',alignItems:'center',marginBottom:'5px'}}>
            {declineStatus === 'pending' && (
            <>
                <Button style={{ color: 'white',borderRadius:'20px',padding:'3px'}}  onClick={() => handleDecline(selectedUserId)} variant='contained' color='primary'>Yes</Button>
                <Button style={{ color: 'white',borderRadius:'20px',padding:'3px'}}   onClick={() => {setShowDeclineDialog(false)}} variant='contained' color='primary'>No</Button>
            </>
            )}
            {declineStatus === 'DECLINED' && (
                <Button style={{ color: 'white',borderRadius:'16px'}}   onClick={() => {setShowDeclineDialog(false);}} variant='contained' color='primary'>OK</Button>
            )}
            {declineStatus === 'ERROR' && (
                <Button style={{ color: 'white',borderRadius:'16px'}}   onClick={() => {setShowDeclineDialog(false);}} variant='contained' color='primary'>OK</Button>
            )}
        </DialogActions>
    </Dialog>
    </>
  );
};

export default Payout