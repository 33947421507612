import React, { useState, useEffect } from 'react';
import '../css/ManualPayment.css'
import axios from 'axios';


const ManualPayment = () => {
    const jwtToken="Bearer "+ localStorage.getItem('token')
    const [formData, setFormData] = useState({
        userId: '',
        amount: '',
        currency: '',
        status: '',
        orderId: '',
        paymentId: '',
        paymentMode:'MANUAL'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        axios.post(`https://www.cyberpaneltest.website:8282/payment/updatepaymentorder/manual`, formData, {headers: {Authorization: jwtToken}})
        .then(response => {
            console.log('Form submitted successfully:', response.data);
            alert('Form submitted successfully..');
        })
        .catch(error => {
            console.error('Error submitting form:', error);
        });
    };

    return (
        <div className='manual-payment'>
            <div className='manual-payment-form'>
            <h2>Manual Payment</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>User ID:</label>
                        <input type="text" name="userId" value={formData.userId} onChange={handleChange} placeholder='User ID' required/>
                    </div>
                    <div className="form-group">
                        <label>Amount:</label>
                        <input type="number" name="amount" value={formData.amount} onChange={handleChange} placeholder='Amount' required/>
                    </div>
                    <div className="form-group">
                        <label>Currency:</label>
                        <input type="text" name="currency" value={formData.currency} onChange={handleChange} placeholder='Currency' required/>
                    </div>
                    <div className="form-group">
                        <label>Status:</label>
                        <input type="text" name="status" value={formData.status} onChange={handleChange}  placeholder='Status' required/>
                    </div>
                    <div className="form-group">
                        <label>Order Id:</label>
                        <input type="text" name="orderId" value={formData.orderId} onChange={handleChange} placeholder='Order ID' required/>
                    </div>
                    <div className="form-group">
                        <label>Payment Id:</label>
                        <input type="text" name="paymentId" value={formData.paymentId} onChange={handleChange} placeholder='Payment ID' required/>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default ManualPayment;
