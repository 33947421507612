import React, { useState,useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Payin from './pages/Payin.jsx';
import Payout from './pages/Payout.jsx';
import Kyc from './pages/Kyc.jsx';
import Logout from './pages/Logout.jsx';
import RecentActivity from './pages/RecentActivity.jsx';
import ManualPayment from './pages/ManualPayment.js';
import Adminlogin from './components/Adminlogin.jsx';

const App = () => {
  const[isLoggedIn ,setIsLoggedIn] = useState(false);
  
  useEffect(() => {
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    if (storedLoginStatus) {
      setIsLoggedIn(JSON.parse(storedLoginStatus));
    }
   } , []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', JSON.stringify(true));
  };
  
  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
  };
  
  return (
    <BrowserRouter>
    {
    isLoggedIn ? (
      <Sidebar onLogout={handleLogout}>
        <Routes>
          <Route path="/" element={<RecentActivity />} />
          <Route path="/payin" element={<Payin />} />
          <Route path="/payout" element={<Payout />} />
          <Route path="/kyc" element={<Kyc />} />
          <Route path="/manualpayment" element={<ManualPayment />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Sidebar>
    ):( <Adminlogin onLogin={handleLogin}/>)}
      
    </BrowserRouter>
  );
};

export default App;