import React, { useState, useEffect } from 'react';
import UserIcon from '../assest/Total active icon.png';
import Inactive from "../assest/Total inactive icon.png";
import Ammount from "../assest/Total Ammount.png";
import Active from "../assest/Total active icon.png";
import Profile from "../assest/woman-with-digital-tablet-cafe.jpg"
import CloseIcon from '@mui/icons-material/Close';
import '../css/RecentActivity.css'
import axios from 'axios';
import { Pagination } from '@mui/material';

const RecentActivity = () => {
    const jwtToken="Bearer "+ localStorage.getItem('token')
    const [totalUserData, setTotalUserData] = useState([]);
    useEffect(() => {
      axios.get(`https://www.cyberpaneltest.website:8282/admin/gettotaluserdetail`, {headers: {Authorization: jwtToken}} )
        .then((res) => {
           setTotalUserData(res.data);
        })
        .catch((err) => console.error(err));
    }, []);

    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(getToday());
    const [endDate, setEndDate] = useState(null);
    const [apiUrl, setApiUrl] = useState();
    const [pageNo, setPageNo] = useState(0);
    const [totalpage, setTotalPage] = useState(1); 
      
    useEffect(() => {
      setData([]);
      if(endDate) { 
        const apiUrl = `https://www.cyberpaneltest.website:8282/admin/recentindaterange?startDate=${endDate}&endDate=${startDate}&page=${pageNo}`;
        setApiUrl(apiUrl);
      }
      else {
        const apiUrl = `https://www.cyberpaneltest.website:8282/admin/recentactivitybydate?date=${startDate}&page=${pageNo}`;
        setApiUrl(apiUrl);
      }
      axios.get(apiUrl, {headers: {Authorization: jwtToken}} )
        .then((res) => {
          setData(res.data.content);
          setTotalPage(res.data.totalPages);
          populateTable();
        })
        .catch((err) => console.error(err));       
    }, [startDate, endDate, pageNo, apiUrl]);

    const handleSortChange = (event) => {
    const selectedOption = event.target.value;
    switch (selectedOption) {
      case 'today':
        setStartDate(getToday());
        setEndDate(null);
        setPageNo(0);
        break;
      case 'yesterday':
        setStartDate(getYesterday());
        setEndDate(null);
        setPageNo(0);
        break;
      case 'oneWeek':
        setStartDate(getToday());
        setEndDate(getOneWeek());
        setPageNo(0);
        break;  
      case 'twoWeeks':
        setStartDate(getToday());
        setEndDate(gettwoWeeks());
        setPageNo(0);
        break;
        case 'last30Days':
          setStartDate(getToday());
          setEndDate(getLast30Days());
          setPageNo(0);
          break;
        case 'thisMonth':
          setStartDate(getToday());
          setEndDate(getThisMonth());
          setPageNo(0);
          break;
        case 'lastMonth':
          setStartDate(getThisMonth());
          setEndDate(getLastMonth());
          setPageNo(0);
          break;
      default:
        break;
    }
    };

  function getFormattedDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;  
  }
  function getToday() {
    const today = new Date();
    return getFormattedDate(today);
  }
  function getYesterday() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return getFormattedDate(yesterday);
  }
  function getOneWeek() {
    const oneWeek = new Date();
    oneWeek.setDate(oneWeek.getDate() - 6);
    return getFormattedDate(oneWeek);
  }
  function gettwoWeeks() {
    const twoWeeks = new Date();
    twoWeeks.setDate(twoWeeks.getDate() - 13);
    return getFormattedDate(twoWeeks);
  }
  function getLast30Days() {
    const last30Days = new Date();
    last30Days.setDate(last30Days.getDate() - 29);
    return getFormattedDate(last30Days);
  }
  function getThisMonth() {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return getFormattedDate(firstDayOfMonth);
  }       
  function getLastMonth() {
    const today = new Date();
    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    return getFormattedDate(firstDayOfLastMonth);
  }

  const populateTable = () => {
    return data.length > 0 ?(
    data.map((item, index) => (
      <tr key={index}>
        <td>{item.userId}</td>
        <td>{item.firstName}</td>
        <td>{item.paymentDate}</td>
        <td className='status-button'><button className={item.paymentStatus === 'PAID' ? 'completeStyle':'pendingStyle' }>{item.paymentStatus}</button></td>
        <td><button className='view-profile-btn'  onClick={() => handleShowProfile(item.userId)}>View Profile</button></td>
      </tr>
    ))
    ):(<tr><td colSpan={5} style={{}} >NO Content</td></tr>)
    };

    const [userProfileData, setUserProfileData] = useState([]);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const handleShowProfile = (userId) => {
      setPopupVisible(true);
      const profileData= data.find(user => user.userId === userId);
      setUserProfileData(profileData)
    };
    const handleCloseProfile = () => {
      setPopupVisible(false);
    };

  return (
  <>
      <div className='recent_activity_section scrollbar' >
      <div className="box">
        <div className="sub-box">
          <img src={UserIcon} alt="" />
          <div className="sub-box-list" >
            <h1>{totalUserData.totaluser}</h1>
            <small>Total User</small>
          </div>
        </div>
        <div className="sub-box">
          <img src={Active} alt="" />
          <div className="sub-box-list" >
            <h1>{totalUserData.activeUser}</h1>
            <small>Total Active</small>
          </div>
        </div>
        <div className="sub-box">
          <img src={Inactive} alt="" />
          <div className="sub-box-list">
            <h1>{totalUserData.inActiveUser}</h1>
            <small>Total Inactive</small>
          </div>
        </div>
        <div className="sub-box">
          <img src={Ammount} alt="" />
          <div className="sub-box-list" >
            <h1>{totalUserData.totalAmount}</h1>
            <small>Total Amount</small>
          </div>
        </div>
      </div>
      
      <article>
        <div className='table-head' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>Recent Activity</h3>
          <div className='sorting'>
            <label>SORT BY : </label>
            <select id="sortSelect" onChange={(e) => handleSortChange(e)}>
              <option id="sortToday" value="today">Today</option>
              <option id="sortYesterday" value="yesterday">Yesterday</option>
              <option id="sortOneWeek" value="oneWeek">1 Week Ago</option>
              <option id="sortTwoWeek" value="twoWeeks">2 Weeks Ago</option>
              <option id="sortThisMonth" value="thisMonth">This Month</option>
              <option id="sortLast30Days" value="last30Days">Last 30 Days</option>
              <option id="sortLastMonth" value="lastMonth">Last Month</option>
            </select>

          </div>
        </div>

        <div className="recent_scroll">
          <table className="dynamic-table">
            <thead>
              <tr>
                <th>User Id</th>
                <th>Name</th>
                <th>Date/Time</th>
                <th className='containerStyle'>Payment Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {populateTable()}
            </tbody>
          </table>
        </div>
      </article>

      {isPopupVisible && (
        <div className="popup">
          <div className="Aside">
            <button onClick={handleCloseProfile}><CloseIcon style={{ position: 'absolute', top: '10', right: 20, cursor: 'pointer' }} /></button>
            <div className="Aside-img">
              <img src={Profile} />
              <h2>{userProfileData.firstName} {userProfileData.lastName}</h2>
              <p>Web Developer and Designer</p>
            </div>

            <div className="Aside-item" id="popupBox">
              <div className="text">
                <div className="list">
                  <p>User-Id</p>
                  <p>-</p>
                  <p>{userProfileData.userId}</p>
                </div>
                <div className="list">
                  <p>Email </p>
                  <p>-</p>
                  <p>{userProfileData.email}</p>
                </div>
                <div className="list">
                  <p>Phone</p>
                  <p>-</p>
                  <p>{userProfileData.contactNo}</p>
                </div>
                <div className="list">
                  <p>Address</p>
                  <p>-</p>
                  <p>abc,Abcdefghijl,Abcdefghijl</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      </div>

      <div className="pagination">
        <Pagination
            count={totalpage}
            color="primary"
            defaultPage={1}
            page={pageNo+1}
            onChange={(e, value) => setPageNo(value-1)}
            style={{ boxShadow: '0px 3px 10px gray',borderRadius:'6px',padding:'5px 0px'}} 
            sx={{
                '& .MuiPaginationItem-root': { 
                  border: 'none',
                },
              }}
          />
      </div>
  </>
  );
};
export default RecentActivity;