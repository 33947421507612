import React, { useState, useEffect } from 'react';
import '../css/Payin.css'
import axios from 'axios';
import { Pagination } from '@mui/material';


const Payin = () => {
    const jwtToken="Bearer "+ localStorage.getItem('token')
    const [data, setData] = useState([]);
    const [apiUrl, setApiUrl] = useState();
    const [startDate, setStartDate] = useState(getToday());
    const [endDate, setEndDate] = useState(null);
    const [pageNo, setPageNo] = useState(0);
    const [totalpage, setTotalPage] = useState(1);
    
  useEffect(() => {
    setData([]);
      if (endDate) {
        const apiUrl = `https://www.cyberpaneltest.website:8282/admin/userpaidtransactionbydate?date=${endDate}&endDate=${startDate}&page=${pageNo}`;
        setApiUrl(apiUrl);
      }
      else {
        const apiUrl = `https://www.cyberpaneltest.website:8282/admin/userpaidtransactionbydate?date=${startDate}&page=${pageNo}`;
        setApiUrl(apiUrl);
      }      
      axios.get(apiUrl, {headers: { Authorization: jwtToken}} )
        .then((res) => {
          setData(res.data.content);
          setTotalPage(res.data.totalPages);
          populateTable();
        })
        .catch((err) => console.error(err));       
  }, [startDate, endDate, pageNo, apiUrl]);

  const handleSortChange = (event) => {
    const selectedOption = event.target.value;
    switch (selectedOption) {
      case 'today':
        setStartDate(getToday());
        setEndDate(null);
        setPageNo(0);
        break;
      case 'yesterday':
        setStartDate(getYesterday());
        setEndDate(null);
        setPageNo(0);
        break;
      case 'oneWeek':
        setStartDate(getToday());
        setEndDate(getOneWeek());
        setPageNo(0);
        break;  
      case 'twoWeeks':
        setStartDate(getToday());
        setEndDate(gettwoWeeks());
        setPageNo(0);
        break;
        case 'last30Days':
          setStartDate(getToday());
          setEndDate(getLast30Days());
          setPageNo(0);
          break;
        case 'thisMonth':
          setStartDate(getToday());
          setEndDate(getThisMonth());
          setPageNo(0);
          break;
        case 'lastMonth':
          setStartDate(getThisMonth());
          setEndDate(getLastMonth());
          setPageNo(0);
          break;  
      default:
        break;
    }
  };


  function getFormattedDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
  function getToday() {
    const today = new Date();
    return getFormattedDate(today);
  }
  function getYesterday() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return getFormattedDate(yesterday);
  }
  function getOneWeek() {
    const oneWeek = new Date();
    oneWeek.setDate(oneWeek.getDate() - 6);
    return getFormattedDate(oneWeek);
  }
  function gettwoWeeks() {
    const twoWeeks = new Date();
    twoWeeks.setDate(twoWeeks.getDate() - 13);
    return getFormattedDate(twoWeeks);
  }
  function getLast30Days() {
    const last30Days = new Date();
    last30Days.setDate(last30Days.getDate() - 29);
    return getFormattedDate(last30Days);
    }
  function getThisMonth() {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return getFormattedDate(firstDayOfMonth);
    }       
  function getLastMonth() {
    const today = new Date();
    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    return getFormattedDate(firstDayOfLastMonth);
    }

  const populateTable = () => {
    return data.length > 0 ?(
    data.map((item, index) => (
      <tr key={index}>
        <td>{item.userId}</td>
        <td>{item.firstName}</td>
        <td>{item.referralId}</td>
        <td>{item.paymentDate}</td>
        <td className='status-button'><button className={item.paymentStatus === 'PAID' ? 'paidStyle':'unpaidStyle' }>{item.paymentStatus}</button></td>
      </tr>
    ))
    ):(<tr><td colSpan={5} style={{}} >NO Content</td></tr>)
  };

  return (
  <>
    <div className='payinsection scrollbar' >    
      <article className='article'>
        <div className='table_head' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>Pay In</h3>
          <div className='sortings'>
            <label>SORT BY : </label>
            <select id="sortSelects" onChange={(e) => handleSortChange(e)}>
              <option id="sortToday" value="today">Today</option>
              <option id="sortYesterday" value="yesterday">Yesterday</option>
              <option id="sortOneWeek" value="oneWeek">1 Week Ago</option>
              <option id="sortTwoWeek" value="twoWeeks">2 Weeks Ago</option>
              <option id="sortThisMonth" value="thisMonth">This Month</option>
              <option id="sortLast30Days" value="last30Days">Last 30 Days</option>
              <option id="sortLastMonth" value="lastMonth">Last Month</option>
            </select>
          </div>
        </div>
        <div className="payin_scroll">
          <table className="dynamic_table">
            <thead>
              <tr>
                <th>UserId</th>
                <th>firstName</th>
                <th>referalId</th>
                <th>paymentDate</th>
                <th className='containerStyle'>paymentStatus</th>
              </tr>
            </thead>

            <tbody>
              {populateTable()}
            </tbody>
          </table>
        </div>
      </article>   
    </div>

    <div className="pagination">
      <Pagination
          count={totalpage}
          color="primary"
          defaultPage={1}
          page={pageNo+1}
          onChange={(e, value) => setPageNo(value-1)}
          style={{ boxShadow: '0px 3px 10px gray',borderRadius:'6px',padding:'5px 0px'}} 
          sx={{
              '& .MuiPaginationItem-root': {
              border: 'none',
            },
          }}
      />     
    </div>
  </>
  );
};

export default Payin;