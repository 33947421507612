import React, { useState } from 'react';
import Img from '../../src/assest/2GVR logo.png'
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaPaypal,
    FaIdCard 
}from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'



const Sidebar = ({children, onLogout}) => {
    const[isOpen ,setIsOpen] = useState(false);
    const toggle = () => setIsOpen (!isOpen);
    const navigate = useNavigate();

    const handleLogoutButton = () => {
    onLogout();
    localStorage.removeItem('token')
    }

    const menuItem=[
        {
            path:"/",
            name:"Dashboard",
            icon:<FaTh/>
        },
        {
            path:"/payin",
            name:"Pay In",
            icon:<FaPaypal/>
        },
        {
            path:"/payout",
            name:"Pay Out",
            icon:<FaUserAlt/>
        },
        {
            path:"/kyc",
            name:"KYC",
            icon:<FaIdCard/>
        },
        {
            path:"/manualpayment",
            name:"Manual Payment",
            icon:<FaPaypal/>
        },
        {
            path:"/logout",
            name:"Logout",
            icon:<AiOutlineLogout/>
        },
       
    ]

  const [userId, setuserId] = useState('');

  localStorage.getItem('userId')

  console.log(userId);

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  localStorage.getItem('isLoggedIn')
  console.log();

  if (isLoggedIn === 'true') {
    navigate('/')
  }

    return (
        <div className="container">
           <div style={{width: isOpen ? "200px" : "50px"}} className="sidebar">
               <div className="top_section">
               <img style={{display: isOpen ? "block" : "none",width:'50%',margin:'auto',height:'60px'}} className="logo" src={Img}></img>
                   <div style={{marginLeft: isOpen ? "50px" : "0px"}} className="bars">
                       <FaBars onClick={toggle}/>
                   </div>
               </div>
              
               {
                   menuItem.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link"  activeClassName="active">
                           <div className="icon">
                           {item.name === 'Logout' ? (<div onClick={handleLogoutButton}>{item.icon}</div>) : 
                                    (item.icon) 
                                }
                           </div>
                           <div style={{display: isOpen ? "block" : "none",fontSize:'16px'}} className="link_text">
                                {item.name === 'Logout' ? (<div onClick={handleLogoutButton}>{item.name}</div>) : 
                                    (item.name) 
                                }
                           </div>
                       </NavLink>
                   ))
               }
           </div>
           <main>{children}</main>
        </div>
    );
};

export default Sidebar;