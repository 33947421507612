import '../css/Adminlogin.css';
import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useHistory } from 'react-router-dom';
 
const Adminlogin = ({ onLogin }) => {
  const navigate = useNavigate();
  const [pass, setPassword] = useState("");
  const [userId, setuserId] = useState("");

  const clickHandleLogin = () => {
    onLogin();
  }

  const handleLogin = async () => {
    try {
      const loginresponse = await axios
        .post(
          "https://www.cyberpaneltest.website:8282/authenticate/loginuser",
 
          {
            userId: userId,
            pass: pass,
          }
        )
        .then((response) => {
          // console.log(response);
          const responseData = response.data;
          console.log(responseData);
          console.log(responseData.USERNAME);
          const token = responseData["JWT-TOKEN"];
          console.log(token);
          localStorage.setItem("token", token);
          if (token) {
            clickHandleLogin()
            navigate('/');
            // localStorage.setItem('token', token);
            // const userRole = response.data.role;
            // console.log(userRole);
 
            // if (userRole === "ADMIN") {
            //   window.location.href = "/admin";
            // } else {
            //   window.location.href = "/user";
            // }
          } else {
            console.log("Request was not successful");
            // Handle unsuccessful login
          }
        })
        .catch((error) => {
          alert(
            "Userid or password may wrong. Please enter correct credentials."
          );
 
          console.error("Error:", error);
        });
    } catch (error) {
      console.log("Login failed", error);
    }
  };
 
  
  return (
<div class="main_login">
<div class="container">
<div class="login_container">
<h1>Login</h1>
          {/* <input
            type="email"
            placeholder=" Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          /> */}
<input
            type="text"
            placeholder="userId"
            value={userId}
            onChange={(e) => setuserId(e.target.value)}
          />
<input
            type="password"
            placeholder="password"
            value={pass}
            onChange={(e) => setPassword(e.target.value)}
          />
<button type="submit" onClick={handleLogin}>Login</button>
</div>
</div>
</div>
  );
};
 
export default Adminlogin
